<template>
  <div v-if="(!subscribedPlan || !subscribedPlan.amount) && (!me || (me && !me.ltd_tier))">
    <div class="card">
      <h5 class="card-title mb-3">
        {{ $t('payment.subscription') }}
      </h5>

      <div class="text-center mt-3" v-if="loading || isLoading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else>
        <div class="price-annually">
          <p class="mb-0">{{ $t('pricing.save-money-annually') }}</p>
          <div class="switches-container">
            <input type="checkbox" id="switch" name="switchPlan" v-model="isAnnual" />
            <label for="switch">{{ $t('pricing.monthly') }}</label>
            <label for="switch">{{ $t('pricing.annually') }}</label>
            <div class="switch-wrapper">
              <div class="switch">
                <div>{{ $t('pricing.monthly') }}</div>
                <div>{{ $t('pricing.annually') }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row flex-column text-center mt-4">
          <p class="banner mt-0">{{ $t('pricing.more-hours-annually') }}</p>
        </div>
        <div class="card-plan-wrapper">
          <div
            class="col-sm card-plan"
            v-for="plan in plans"
            :key="plan.id"
            :class="{
              'border-success': subscription && plan.id == subscription.stripe_plan,
            }"
          >
            <div type="button" class="btn btn-link btn-block" @click="selectPlan(plan)">
              <i
                :class="{
                  'bi-circle': subscription && plan.id != subscription.stripe_plan,
                  'bi-check-circle-fill': subscription && plan.id == subscription.stripe_plan,
                  'text-success': subscription && plan.id == subscription.stripe_plan,
                }"
              ></i>
              <h4 :class="{ 'text-success': subscription && plan.id == subscription.stripe_plan }">
                {{ $t(`pricing.${plan.nickname.split('-')[0]}`) }}
              </h4>
              <p :class="{ 'text-success': subscription && plan.id == subscription.stripe_plan }">
                <b v-if="plan.metadata.monthly_minutes >= 120" :class="{ 'text-warning': isAnnual }">
                  {{ (plan.metadata.monthly_minutes / 60).toFixed(0) }}
                </b>
                <b v-else>{{ plan.metadata.monthly_minutes }}</b>
                <span v-if="plan.metadata.monthly_minutes >= 120" :class="{ 'text-warning': isAnnual }">
                  {{ $t('pricing.hours') }} / {{ $t('pricing.month') }}
                </span>
                <span v-else> {{ $t('pricing.minutes') }} / {{ $t('pricing.month') }}</span>
              </p>
              <b v-if="plan.amount > 0">US$ {{ (plan.metadata.original_amount / 100).toFixed(2) }} </b>
              <small v-if="plan.amount > 0">
                <br />
                {{ $t('pricing.per-month') }}
                <span v-if="plan.interval == 'year'"> {{ $t('pricing.billed-yearly') }}. </span>
              </small>

              <div v-if="subscription && plan.id == subscription.stripe_plan">
                <small v-if="subscription">
                  {{ $t('payment.updated-at') }} {{ subscription.updated_at | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }}
                </small>
                <br />
                <small v-if="subscription && subscription.stripe_subscription.status == 'trialing' && subscription.trial_ends_at">
                  {{ $t('payment.trial-ends-at') }} {{ subscription.trial_ends_at | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }}
                </small>
                <small v-else-if="subscription && subscription.stripe_subscription.current_period_end">
                  {{ $t('payment.renews-at') }}
                  {{ (subscription.stripe_subscription.current_period_end * 1000) | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-3">
          <hr class="mt-5 mb-4 border-gray-dark" />
          <router-link to="/pricing">
            <SvgIcon name="info-octogon" stroke="#ffffff" width="18px" class="mr-1 mb-1" />
            {{ $t('payment.more-info') }}
          </router-link>
        </div>
      </div>
    </div>

    <b-modal id="modal-downgrading" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('payment.downgrading') }}

        <button class="btn btn-link p-0" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <div class="alert alert-danger">
        {{ $t('payment.confirm-downgrade') }}
      </div>

      <PlanDetails :selectedPlan="selectedPlan" v-if="selectedPlan" />

      <div class="d-flex justify-content-between mt-5">
        <button type="button" class="btn btn-block btn-primary" @click.prevent="updatePlan">
          <div class="spinner-border text-white" role="status" v-if="isLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <span v-else>
            {{ $t('payment.confirm') }}
          </span>
        </button>
      </div>
    </b-modal>

    <b-modal id="modal-upgrading" centered hide-footer size="sm">
      <template #modal-header="{ close }">
        {{ $t('payment.upgrading') }}

        <button class="btn btn-link p-0" size="sm" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <PlanDetails :me="me" :selectedPlan="selectedPlan" v-if="selectedPlan" />

      <div class="row m-0 justify-content-center align-items-center mt-3 mx-auto">
        <div v-if="couponIsValid != true" class="form-group p-0 col-6">
          <label>{{ $t('payment.coupon') }}</label>
          <input
            type="text"
            v-model="couponInput"
            class="form-control"
            :class="{
              'is-valid': couponIsValid === true,
              'is-invalid': couponIsValid === false,
            }"
          />
        </div>
        <small v-if="couponIsValid === true" class="text-info col-2">{{ selectedPlan.coupon }}</small>
        <button
          v-if="couponIsValid != true"
          type="button"
          class="btn btn-sm btn-info col-2 mt-3 ml-1 mr-1"
          @click.prevent="loadCoupon(couponInput, selectedPlan.id)"
        >
          {{ $t('payment.apply-coupon') }}
        </button>
        <button
          v-if="couponIsValid === true"
          type="button"
          class="btn btn-sm btn-default col-3 ml-1 mr-1"
          @click.prevent="
            couponInput = ''
            loadCoupon(couponInput, selectedPlan.id)
          "
        >
          X {{ $t('payment.remove-coupon') }}
        </button>
        <div v-if="loadingCoupon" class="text-center col-1">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <small v-if="couponIsValid === true" class="col-6">{{ $t('payment.coupon-discount-info') }}</small>
      </div>

      <button v-if="couponInput == '' || couponIsValid" type="button" class="btn btn-block btn-primary mt-3" @click.prevent="updatePlan">
        {{ $t('payment.confirm') }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'
import PlanDetails from './PlanDetails'

export default {
  name: 'account-subscription',

  props: ['tryUpdateSubscription', 'payment_method', 'isLoading'],

  components: {
    PlanDetails,
    SvgIcon,
  },

  data() {
    return {
      loading: true,
      loadingCoupon: false,
      selectedPlan: null,
      plans: null,
      isAnnual: true,
      subscribedPlan: null,
      couponInput: '',
      couponIsValid: null,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      subscription: 'user/subscription',
      monthlyPlans: 'payment/monthlyPlans',
      yearlyPlans: 'payment/yearlyPlans',
      freePlan: 'payment/freePlan',
      coupon: 'payment/coupon',
    }),
  },

  mounted() {
    this.getAllRequests()
  },

  methods: {
    ...mapActions({
      getSubscription: 'user/GET_SUBSCRIPTION',
      getYearlyPlans: 'payment/GET_YEARLY_PLANS',
      getMonthlyPlans: 'payment/GET_MONTHLY_PLANS',
      getFreePlan: 'payment/GET_FREE_PLAN',
      getUpgradeLink: 'user/GET_UPGRADE_LINK',
      getCoupon: 'payment/GET_COUPON',
    }),

    async getAllRequests() {
      try {
        this.loading = true
        await this.getFreePlan()
        await this.getSubscription()
        await this.getYearlyPlans()
        await this.getMonthlyPlans()
        if (this.me.ltd_tier) {
          await this.getUpgradeLink()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }

      this.setPlans()

      if (this.subscription) {
        this.subscribedPlan = this.subscription.stripe_subscription.plan
      }
    },

    async loadCoupon(code, planId) {
      if (code == '' && this.selectedPlan) {
        this.couponIsValid = null
        this.selectedPlan.coupon = null
        this.selectedPlan.amount = parseInt(this.selectedPlan.amount_decimal)
      } else {
        this.loadingCoupon = true
        await this.getCoupon({
          code: code,
          planId: planId,
        })
        this.loadingCoupon = false
      }
    },

    setPlans() {
      if (this.isAnnual) this.plans = this.yearlyPlans
      else this.plans = this.monthlyPlans
    },

    selectPlan(plan) {
      if (this.subscription.stripe_plan != plan.id) {
        this.selectedPlan = plan
        this.loadCoupon('', '')
        this.couponInput = ''
        if (process.env.NODE_ENV !== 'development' && !this.payment_method && plan.amount > 0) {
          this.$bvToast.toast(this.$t('payment.select-payment-method'), {
            variant: 'info',
            title: this.$t('payment.no-payment-method'),
            toaster: 'b-toaster-top-center',
            solid: true,
          })
        } else {
          if (plan.amount <= this.subscription.stripe_subscription.plan.amount) {
            this.$bvModal.show('modal-downgrading')
          } else {
            this.$bvModal.show('modal-upgrading')
          }
        }
      }
    },

    updatePlan() {
      this.tryUpdateSubscription(this.selectedPlan)
    },
  },

  watch: {
    isAnnual(val) {
      this.setPlans()
    },
    coupon: {
      handler(val) {
        if (this.selectedPlan) {
          if (val && val.isValid) {
            this.selectedPlan.amount = (parseInt(this.selectedPlan.amount_decimal) * (100 - val.percent_off)) / 100
            this.couponIsValid = true
            this.selectedPlan.coupon = val.code
          } else {
            this.selectedPlan.amount = parseInt(this.selectedPlan.amount_decimal)
            this.couponIsValid = false
            this.selectedPlan.coupon = null
          }
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

::v-deep .modal-dialog {
  @media (max-width: 1024px) {
    margin-top: 160px;
  }
}
.banner {
  font-weight: 400;
  padding: 5px 12px;
  color: $white;
  background-color: $primary;
  border-radius: 20px;
  margin: 1rem;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 0 15px 15px;
    font-size: 14px;
  }
}
.card-plan-wrapper {
  display: flex;
  gap: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .card-plan {
    border: 1px solid $gray-700;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: $primary;
    }
  }
}
.btn {
  max-height: fit-content;
}
.price-annually {
  position: absolute;
  top: 23px;
  right: 23px;
  display: flex;
  align-items: center;
  gap: 15px;
  p {
    font-size: 14px;
    line-height: normal;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.switches-container {
  background: $primary;
  label {
    color: $white;
  }
}
.switch {
  background: $white;
  div {
    color: $primary;
  }
}
</style>
