<template>
  <section class="library">
    <div class="row">
      <div class="col-md-6 col-sm-6 library__title" v-if="$route.name != 'library' && $route.name != 'new'">
        <h5 class="title-page mb-4">
          {{ $t('library.title') }}
        </h5>
      </div>
      <div class="col-md-12 col-sm-12 library__title" v-if="$route.name == 'new'">
        <h5 class="title-page mb-4">
          {{ $t('library.subtitle') }}
        </h5>
      </div>

      <div class="col-md-6 col-sm-6 library__search">
        <div class="d-flex">
          <div class="input-group wrapper-input-search">
            <input type="text" class="form-control" @keyup.enter="trySearch" v-model="query" :placeholder="$t('library.search-placeholder')" />
            <div class="input-group-append">
              <button class="btn btn-secondary px-0" type="button" @click.prevent="clearSearch" v-if="query != ''">
                <span class="material-icons-round md-18">clear</span>
              </button>
              <button class="btn btn-secondary" type="button" @click.prevent="trySearch">
                <span class="material-icons-round md-18">search</span>
              </button>
            </div>
          </div>

          <button @click="sendNewMediaToParent" class="btn btn-primary text-nowrap ml-3">
            {{ $t('library.send-new-media') }}
          </button>
        </div>
      </div>
    </div>

    <div class="custom-control custom-switch mb-4 mt-3">
      <input type="checkbox" class="custom-control-input" id="show-projects" v-model="model.show_projects" @change="trySearch" />
      <label class="custom-control-label" for="show-projects">
        <p>{{ $t('library.show-projects') }}</p>
        <small> {{ $t('library.no-exclude-projects') }}</small>
      </label>
    </div>

    <div class="library__selected" v-if="!$route.params.type && $route.name != 'library' && from_library.length">
      <span>{{ $t('library.selected', { count: from_library.length }) }}</span>
      <button @click="sendNewMediaToParent" class="btn btn-link text-success">
        <span> {{ $t('library.button-media') }} </span>
      </button>
    </div>

    <div class="library__selected py-4" v-if="$route.params.order">
      <span v-if="$route.params.type && $route.params.type == 'overlay'">
        {{ $t('library.choose-video') }}
      </span>
      <span v-else-if="$route.params.order != 1">
        {{ $t('library.insert-files-after') }}
        <span class="text-tertiary">{{ $t('library.scene') }} {{ $route.params.order - 1 }}</span>
      </span>
      <span v-else>
        <span class="text-tertiary">{{ $t('library.insert-files-at-start') }}</span>
      </span>
    </div>

    <div class="text-center" v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else>
      <div class="row" v-if="library && library">
        <div class="col-sm-12 text-center py-5" v-if="!library.length">
          <h6 class="text-primary" v-if="!searching && query == ''">{{ $t('library.no-results') }}</h6>
          <h6 class="text-primary" v-if="query != '' && searching">{{ `${$t('library.no-results-found-for')} "${query}"` }}</h6>
        </div>
        <div class="col-sm-12 text-center" v-if="library.length && query != '' && searching">
          <h6 class="text-primary">{{ $t('library.showing-results-for') }} "{{ query }}"</h6>
        </div>
      </div>

      <div class="wrapper-list-media" v-if="library && library">
        <div class="wrapper-list-media__item" v-for="(file, index) in library" :key="file.id">
          <div class="card-media">
            <div
              class="wrapper-list-media__item--checkbox"
              v-if="!$route.params.type && file.raw_preview_render == 1 && file.working_progress_raw == 100 && $route.name != 'library'"
            >
              <i
                :class="{
                  'bi-square text-default':
                    from_library.filter((x) => x.isScene && x.project_id === file.id).length === 0 &&
                    selected.findIndex((x) => x.id === file.id) < 0 &&
                    from_library.findIndex((x) => x.id === file.id) < 0,
                  'bi-check-square-fill text-success':
                    from_library.filter((x) => x.isScene && x.project_id === file.id).length > 0 ||
                    selected.findIndex((x) => x.id === file.id) >= 0 ||
                    from_library.findIndex((x) => x.id === file.id) >= 0,
                }"
                @click.prevent="selectFile(file)"
              ></i>
            </div>

            <div
              class="card-media__background"
              :style="{ 'background-image': `url(${file.thumbnailPath || '/img/thumbnail.png'})` }"
              @mouseover="hoveredIndex = index"
              @mouseout="hoveredIndex = null"
              @click.prevent="previewFile(file)"
            >
              <span class="badge card-media__background--badge" v-if="model.show_projects && file.type !== 'library'">
                {{ $t('topbar.project') }}
              </span>
              <span class="card-media__background--overlay" :class="{ visible: hoveredIndex === index }">
                <SvgIcon name="play" stroke="#ffffff" />
              </span>
            </div>

            <small v-if="editing != file.id">
              <p class="card-media__title" v-if="file.raw_preview_render === 1 && file.working_progress_raw === 100">
                <span v-if="file.id == $route.params.id">{{ $t('library.this-project') }}</span>
                <span v-else>{{ file.name }}</span>
                <br />
                <span class="card-media__title--count"> {{ file.scenes_count }}</span>
              </p>
              <p class="p-0 m-0 ml-2 mr-2 text-truncate" v-else-if="file.raw_preview_render === 0 && file.working_progress_raw < 100">
                <span class="spinner-border spinner-border-sm text-light mr-2" role="status"></span>{{ $t('library.processing') }}
                {{ file.working_progress_raw }}%
              </p>
            </small>
            <div v-else class="card-media__edit-content">
              <input type="text" class="form-control form-control-sm" />
              <div class="card-media__edit-content--actions">
                <button type="button" class="btn btn-sm btn-secondary">
                  {{ $t('library.cancel') }}
                </button>
                <button type="button" class="btn btn-sm btn-primary">
                  {{ $t('library.confirm') }}
                </button>
              </div>
            </div>

            <span class="card-media__infos" v-if="from_library.filter((x) => x.isScene && x.project_id === file.id).length > 0">
              {{ $t('library.scenes') }}: {{ from_library.filter((x) => x.isScene && x.project_id === file.id).length }}
              {{ $t('library.scenes-selected') }}
            </span>

            <div class="card-media__actions">
              <button class="btn btn-secondary btn-sm" @click.prevent="diveInto(file)">
                {{ $t('library.open') }}
              </button>
              <button class="btn btn-tertiary btn-sm mt-0" @click.prevent="tryRemove(file)" v-if="file.type == 'library'">
                {{ $t('library.remove') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- === has more === -->
      <div class="text-center" v-if="has_more">
        <button type="button" class="btn btn-sm btn-link" @click.prevent="loadMore" :disabled="load_more_loading">
          <div class="spinner-border spinner-border-sm text-light" role="status" v-if="load_more_loading"></div>
          <span v-if="!load_more_loading">{{ $t('library.load-more') }}</span>
        </button>
      </div>
    </div>
    <div class="selected-toolbar p-3 text-center" v-if="selected.length">
      {{ selected.length }} {{ $t('library.files-selected') }}
      <button type="button" class="btn btn-sm btn-primary" @click.prevent="tryInsert" :disabled="insert_loading">
        <div class="spinner-border spinner-border-sm text-light" role="status" v-if="insert_loading">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="!insert_loading">{{ $t('library.insert') }}</span>
      </button>
    </div>

    <b-modal id="modal-preview-file" modal-class="modal-preview-file" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('library.preview-file') }}
        <button class="btn btn-link p-0" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <VideoPlayer
        v-if="previewing"
        :thumbnail="thumbnail"
        :video="video"
        :subtitles="null"
        :start="start"
        :end="end"
        :autoplay="true"
        :currentTime="currentTime"
        :height="300"
      />
    </b-modal>

    <b-modal id="modal-remove-library" modal-class="modal-remove-library" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('library.confirm-remove-file') }}
        <button class="btn btn-link p-0" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <p v-if="fileToRemove">
        {{ $t('library.confirm-remove-file-text-p1') }}: <b class="text-primary">{{ fileToRemove.name }}</b
        >?
      </p>
      <p>{{ $t('library.confirm-remove-file-text-p2') }}</p>
      <button type="button" class="btn btn-block btn-primary" @click.prevent="confirmRemove">
        {{ $t('library.confirm') }}
      </button>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import VideoPlayer from '@/components/VideoPlayer'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'library-projects',

  props: ['source', 'selectScenes', 'changeNav', 'setProjectQuery', 'selectFiles', 'selectFromLibrary', 'from_library'],

  components: {
    VideoPlayer,
    SvgIcon,
  },

  data() {
    return {
      query: '',
      hoveredIndex: null,
      loading: true,
      insert_loading: false,
      load_more_loading: false,
      searching: false,
      selected: [],
      editing: null,
      has_more: false,
      page: 1,
      fileToRemove: null,
      URL: URL,
      previewing: false,
      thumbnail: null,
      start: null,
      end: null,
      currentTime: null,
      video: null,
      model: {
        show_projects: true,
      },
    }
  },

  computed: {
    ...mapGetters({
      library: 'library/library',
    }),
  },

  async mounted() {
    this.trySearch()
  },

  methods: {
    ...mapMutations({
      setProject: 'library/SET_PROJECT',
    }),

    ...mapActions({
      getLibrary: 'library/GET_LIBRARY',
      searchLibrary: 'library/SEARCH_LIBRARY',
      getById: 'library/GET_BY_ID',
      getStoryboard: 'project/GET_STORYBOARD',
      removeLibrary: 'library/REMOVE_LIBRARY',
    }),

    selectFile(file) {
      if (this.selectFiles) {
        this.selectFromLibrary(file)
      } else {
        const index = this.selected.findIndex((x) => x.id === file.id)
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(file)
        }
      }
    },

    diveInto(file) {
      this.setProject(file)
      this.changeNav('scenes')
    },

    tryRemove(file) {
      this.fileToRemove = file
      this.$bvModal.show('modal-remove-library')
    },

    previewFile(file) {
      this.previewing = false
      this.thumbnail = file.thumbnailPath
      this.start = 0
      this.end = null
      this.currentTime = 0
      this.video = file.export_raw_url
      this.$bvModal.show('modal-preview-file')
      setTimeout(() => {
        this.previewing = true
      }, 200)
    },

    async confirmRemove() {
      this.$bvModal.hide('modal-remove-library')
      window.scrollTo(0, 0)
      this.loading = true
      const result = await this.removeLibrary(this.fileToRemove.id)
      this.fileToRemove = null
      setTimeout(() => {
        this.search()
      }, 400)
    },

    clearSearch() {
      this.query = ''
      this.trySearch()
    },

    trySearch() {
      this.loading = true
      this.page = 1
      this.search()
    },

    loadMore() {
      this.load_more_loading = true
      this.page = this.page + 1
      this.search()
    },

    async search() {
      if (this.query == '') {
        const result = await this.getLibrary({
          page: this.page,
          type: this.model.show_projects ? null : 'library',
        })

        this.has_more = result.current_page < result.last_page ? true : false
        this.searching = false
      } else {
        const result = await this.searchLibrary({
          query: this.query,
          page: this.page,
          type: this.model.show_projects ? null : 'library',
        })
        this.has_more = result.current_page < result.last_page ? true : false
        this.searching = true
      }
      this.loading = false
      this.load_more_loading = false
    },

    async tryInsert() {
      this.insert_loading = true
      let scenes = []
      for (let file of this.selected) {
        let storyboard = await this.getStoryboard({ id: file.id })
        scenes = scenes.concat(storyboard.timeline.scenes)
      }
      this.selectScenes(scenes)
    },

    sendNewMediaToParent() {
      this.changeNav('upload')
    },
  },

  watch: {
    query(val) {
      this.searching = false
      this.setProjectQuery(val)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.library {
  &__selected {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: normal;
    align-items: center;
    border-radius: 8px;
    color: $gray-450;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px 0 15px;
    min-height: 48px;
    padding: 6px 16px;
    background: #171721;
    .btn {
      font-size: 13px;
      font-weight: bold;
      &:hover {
        color: $primary !important;
      }
    }
  }
}

::v-deep {
  .modal-preview-file,
  .modal-remove-library {
    .modal-header {
      padding-bottom: 0;
    }
  }
}
::v-deep {
  .card-media__edit-content {
    margin-top: 15px;
    &--actions {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(2, minmax(95px, 1fr));
      justify-content: space-between;
      gap: 10px;
      .btn {
        text-align: center;
        width: 100%;
      }
    }
  }
}
</style>
